.payment-finish-container {
  margin: 2rem auto;
  color: #404040;

  @media (max-width: 992px) {
    margin: 2rem 1rem;
  }

  .payment-finish-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 4rem;
      width: 4rem;
    }

    .description {
      margin: 2rem 0 2rem 0;
      text-align: center;
    }

    .numeroPedido {
      padding: 25px;
      background: #EEEEEE;
      font-size: 15px;
      text-align: center;
      color: #000;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 5px;
      span {
        margin-bottom: 10px;
      }
      a, .orderNumber {
        font-weight: 600;
        font-size: 38px;
        display: block;
        color: #000;
        text-decoration: none;
        margin: 0;
      }
    }
  }

  .payment-method {
    margin-top:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .cartao-de-credito {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .pix {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .pix-qrcode {
        background-color: #fff;
        padding: 20px;
        margin-top: 5px;
      }

      .pix-copy {
        width: 400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        input {
          background: #e6e9f2;
          cursor: pointer;
          width: 90%;
          border: 1px solid #e6e9f2;
          font-size: 16px;
          padding: 12px 32px 12px 16px;
          flex: 1;
          font-weight: normal;
        }

        button {
          border: 1px solid #e6e9f2;
          background: #fff;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 50px;
          cursor: pointer;
        }

        .file-copy {
          background-size: 24px 24px;
          width: 24px;
          height: 24px;
          display: flex;
          background-image: url('../../../assets/icons/file-copy.svg')
        }
      }
    }

    .boleto {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      span {
        margin-bottom: 10px;
      }
      .boleto-btn {

        width: 250px;
        height: 50px;
        background-color: #fcd64b;
        color: #5a5e5c;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.7;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        border-radius: 5px;
        text-decoration: none;

        i {
          background-size: 24px 24px;
          width: 24px;
          height: 24px;
          display: flex;
          margin-right: 10px;
          background-image: url('../../../assets/icons/icon-boleto.svg')
        }
      }
    }
    .acessar-area-aluno {
      margin-top:25px;
      text-decoration: underline;
      color:#006b3e;
      font-weight: bold;
    }
  }


}
